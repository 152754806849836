import React from "react";
import "./Health.css";
import { ReactComponent as Green } from "../../assets/checkmark_green.svg";
import { ReactComponent as Red } from "../../assets/xmark_red.svg";
import { ReactComponent as Question } from "../../assets/question-mark.svg";
import { Tooltip } from "react-tooltip";

function Health(props) {
  return (
    <div className="health">
      <div className="row">
        <div className="left">
          {props.service}
          <div
            data-tooltip-id="my-tooltip"
            data-tooltip-content={props.tip}
            className="tooltip"
          >
            <Question />
          </div>
          <Tooltip id="my-tooltip" place="top" effect="solid">
            Tooltip for the register button
          </Tooltip>
        </div>
        {typeof props.health == "boolean" ? (
          props.health ? (
            <Green />
          ) : (
            <Red height={22} width={22} />
          )
        ) : (
          <a
            className="health-link"
            href={props.health}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.health}
          </a>
        )}
      </div>
      <div className="row-status">
        {" "}
        {typeof props.health == "boolean"
          ? props.health
            ? "normal"
            : "incident"
          : "third party service"}
      </div>
    </div>
  );
}

export default Health;
