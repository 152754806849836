import React from "react";
import { useState, useEffect } from "react";
import { healthService } from "../../services/Service";
import "./Dashboard.css";
import Health from "../health/Health";

function Dashboard() {
  const [kasper, setKasper] = useState(false);
  const [node, setNode] = useState(false);

  useEffect(() => {
    healthService.kasperHealth().then((res) => setKasper(res));
    healthService.nodeHealth().then((res) => setNode(res));
  }, []);

  return (
    <div className="dashboard">
      <h1 className="dashboard-title">Current Status</h1>
      <div className="container">
        <Health
          service="kasper.playpickup.com"
          health={kasper}
          tip={"background service for prop grading etc."}
        />
        <Health
          service="api.playpickup.com"
          health={node}
          tip={"main crud service for FanPower ecosystem"}
        />
        <Health
          service="Twilio"
          health="https://status.twilio.com/"
          tip={"third party service for otp"}
        />
        <Health
          service="SendGrid"
          health="https://status.sendgrid.com/"
          tip={"third party service for email"}
        />
      </div>
    </div>
  );
}

export default Dashboard;
