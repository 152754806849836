import axios from "axios";

const kasperHealth = async () => {
  return await axios
    .get("https://kasper.playpickup.com/health")
    .then((res) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
};

const nodeHealth = async () => {
  return await axios
    .get("https://api.playpickup.com/health")
    .then((res) => {
      return true;
    })
    .catch((err) => {
      return false;
    });
};

export const healthService = {
  kasperHealth,
  nodeHealth,
};
