import React from "react";
import "./Header.css";
import FP from "../../assets/FP-Dark.png";
function Header() {
  return (
    <div className="Header">
      <div>
        <a
          className="Header-link"
          href="https://fanpower.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          FanPower
        </a>
      </div>
      <img src={FP} alt="FanPower" className="logo" />
      <a
        className="Header-link"
        href="https://portal.fanpower.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        Portal
      </a>
    </div>
  );
}

export default Header;
