import React from "react";
import "./Footer.css";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";

function Footer() {
  return (
    <div className="footer">
      <a
        className="footer-link"
        href="https://www.linkedin.com/company/fanpowersports"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Linkedin height={25} width={25} />
      </a>
      <div>
        © {new Date().getFullYear()} YouBet Technology, Inc. All rights
        reserved.
        <a
          className="footer-link"
          href="https://fanpower.io/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
        <a
          className="footer-link"
          href="https://fanpower.io/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </a>
        <a href="mailto:support@fanpower.io" className="footer-link">
          Help
        </a>
      </div>
      {/* <div> */}
      {/* </div> */}
    </div>
  );
}

export default Footer;
